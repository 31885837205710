import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search-filter"
export default class extends Controller {
  static targets = [ "clearFilter" ]
  static classes = [ "displayNone" ]

  connect() {
    this.checkedCheckboxCount = 0
    this.params = this.searchParams
    this.updateFilter()
  }

  updateCheckedCheckboxCount() {
    this.checkedCheckboxCount = this.selectedCheckboxes.length
  }

  updateFilter() {
    this.checkedCheckboxCount = this.selectedCheckboxes.length
    this.params = this.searchParams

    if (this.checkedCheckboxCount > 0 || this.params.length > 0) {
      this.showClearFilter()
    } else {
      this.hideClearFilter()
    }
  }

  showClearFilter() {
    this.clearFilterTarget.classList.remove(this.displayNoneClass)
  }

  hideClearFilter() {
    this.clearFilterTarget.classList.add(this.displayNoneClass)
  }

  clear() {
    this.selectedCheckboxes.forEach(checkbox => {
      checkbox.checked = false
    });
    location.search = ""
  }

  get searchParams() {
    return window.location.search
  }

  get selectedCheckboxes() {
    return this.checkboxes.filter(checkbox => checkbox.checked)
  }

  get checkboxes() {
    return new Array(...this.element.querySelectorAll("input[type=checkbox]"))
  }
}
