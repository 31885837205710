import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer"

// Connects to data-controller="conversation"
// Targets are associated with data-conversation-target="chatMessages" and
// data-conversation-target="newChatMessage"
export default class extends Controller {
  static targets = [ "chatMessages", "newChatMessage", "chatForm", "sendButton" ]

  connect() {
    this.subscription = consumer.subscriptions.create({ channel: "ConversationChannel", id: this.data.get("id") }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    })
    this.element[this.identifier] = this
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }

  _connected() {
    this.scrollToBottom()
  }

  _disconnected() {
  }

  _received(data) {
    if (data.message) {
      this.chatMessagesTarget.insertAdjacentHTML('beforeend', data.message)
      this.scrollToBottom()

      if (!document.hidden) {
        this.subscription.perform("update_last_read_at")
      }
    }
  }

  clearChatMessage() {
    this.newChatMessageTarget.value = ''
    this.focusChatMessageInput()
    this.disableSendButton()
  }

  focusChatMessageInput() {
    this.newChatMessageTarget.focus()
  }

  enableSendButton() {
    this.sendButtonTarget.disabled = false
  }

  disableSendButton() {
    this.sendButtonTarget.disabled = true
  }

  scrollToBottom() {
    // If false, the bottom of the element will be aligned to the bottom
    // of the visible area of the scrollable ancestor.
    this.element.scrollIntoView(false)
  }

  chatMessageInput(event) {
    let chatMessage = this.newChatMessageTarget.value.trim()

    if (chatMessage) {
      this.enableSendButton()
    } else {
      this.disableSendButton()
    }

    if (event.keyCode === 13 && !event.shiftKey) {
      this.sendChatMessage(event)
      event.preventDefault()
    }
  }

  sendChatMessage(event) {
    event.preventDefault()
    let chatMessage = this.newChatMessageTarget.value.trim()
    if (chatMessage) {
      Rails.fire(this.chatFormTarget, 'submit')
    }
  }
}
