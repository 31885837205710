import PlacesAutocomplete from "stimulus-places-autocomplete"

// Connects to data-controller="places"
export default class extends PlacesAutocomplete {

  static targets = [ "lonlat" ]

  setGeometry(geometry) {
    super.setGeometry(geometry)

    if (this.hasLonlatTarget) {
      this.lonlatTarget.value = [ geometry.location.lng(), geometry.location.lat() ]
    }
  }
}
