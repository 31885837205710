import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'

// Connects to data-controller="dropdown"
export default class extends Controller {
  static classes = [ "open" ]

  connect() {
    useClickOutside(this)
  }
  
  toggle() {
    this.element.classList.toggle(this.openClass)
  }

  hide(event) {
    event.preventDefault()
    this.element.classList.remove(this.openClass)
  }

}
