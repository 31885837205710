import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

// Connects to data-controller="slim-select"
export default class extends Controller {
  static values = {
    placeholder: String,
    options: Object
  }

  connect() {
    this.slimselect = new SlimSelect({
      select: this.element,
      showSearch: false,
      placeholder: this.placeholderValue,
      ...this.optionsValue
    })
  }

  disconnect() {
    this.slimselect.destroy()
  }
}
